import { HttpClient, HttpResponse } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { GridApi } from "ag-grid-community";
import { groupBy, cloneDeep, orderBy } from "lodash-es";
import * as moment from "moment";
import { combineLatest, Subject } from "rxjs";
import { map, takeUntil, tap } from "rxjs/operators";
import { LoaderService } from "src/app/core/services/loader.service";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { DisabledCheckboxRenderer } from "src/app/shared/components/checkbox-renderer/disabled-checkbox-renderer.component";
import { Category } from "src/app/shared/models/category";
import { Supplier } from "src/app/shared/models/Supplier";
import { FileService } from "src/app/shared/services/file.service";
import { InventoryService } from "src/app/shared/services/inventory.service";
import { getHeaderTemplate } from "src/app/shared/utils/ag-grid-helper";
import * as ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver-es';
import {
  dateDiffInDays,
  dateTimeComparator,
  getFileName,
  getFilenameFromContentDisposition,
  parseBooleanValue,
  parseDateTime,
  purchaseOrderNumberComparator,
  saveFile,
} from "src/app/shared/utils/functions";
import { CheckboxRenderer } from "../../shared/components/checkbox-renderer/checkbox-renderer.component";
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from "../../shared/components/confirm-dialog/confirm-dialog.component";
import { PurchaseService } from "../../shared/services/purchase.service";
import { POService } from "../po-creation-tab/po-view/po.service";
import { PoInfoComponent } from "./po-info/po-info.component";
import { POL } from "./pol/pol.component";
import { Port, PortsComponent } from "./ports/ports.component";
import { PurchaseDatesComponent } from "./purchase-dates/purchase-dates.component";
import { PurchaseDocsComponent } from "./purchase-docs/purchase-docs.component";
import { QcPhotosDialogComponent } from "./qc-photos-dialog/qc-photos-dialog.component";
import { SelectRecepientComponent } from "../po-creation-tab/po-view/select-recepient/select-recepient.component";
import { GeneralSettingsService } from "src/app/core/services/general-settings.service";
import { GeneralSettings } from "src/app/core/models/general-settings.model";
import { UserService } from "../../shared/services/user.service";
import { DateTimePickerComponent } from "./date-time-picker/date-time-picker.component";
import { P } from "@angular/cdk/keycodes";

@Component({
  selector: "app-po-props",
  templateUrl: "./po-props.component.html",
  styleUrls: ["./po-props.component.scss"],
})
export class PoPropsComponent implements OnInit, OnDestroy {
  gridApi: GridApi;
  gridColumnApi: any;
  defaultColDef: any;
  columnDefs: any;
  rowData: any;
  baseRowData;
  public rowSelection;
  headerHeight: number;
  public getRowStyle;
  frameworkComponents: any;
  public POLs: POL[];
  public ports: Port[];
  public polNames: string[];
  public portNames: string[];

  public selectedStatus: string = 'OPEN';
  public statuses: string[] = ['ALL', 'PENDING', 'OPEN', 'DELIVERED'];

  searchQuery: string = "";
  suppliers: Supplier[];
  categories: Category[];
  selectedSupplier: Supplier;
  selectedCategory: Category;
  selectedItem: any;
  columnStateSaving = false;
  columnStateResetting = false;
  columnStateRestoring = false;
  defaultPeriodState = true;
  showQuickFilter: boolean = false;
  sortModel: { colId: string; sort: string };
  poScreenConfigStateSubject = new Subject<void>();
  poScreenConfigStorageKey = "poScreenConfigState";
  localStorageObject;
  showUnpaid = false;
  showGoodsReady = false;
  destroyedComponentSubj = new Subject<void>();

  public settings: GeneralSettings;

  currentGridView:
    | "all"
    | "numbers"
    | "vessels"
    | "dates"
    | "docs"
    | "freightCost"
    | "production"
    | "custom";

  periodFilters: { period: string; isChecked: boolean }[] = [
    {
      period: "All",
      isChecked: true,
    },
    {
      period: "7days",
      isChecked: false,
    },
    {
      period: "14days",
      isChecked: false,
    },
    {
      period: "30days",
      isChecked: false,
    },
    {
      period: "60days",
      isChecked: false,
    },
  ];

  constructor(
    private router: Router,
    private snackBar: SnackBarService,
    private loader: LoaderService,
    private purchaseService: PurchaseService,
    private poService: POService,
    private fileService: FileService,
    private inventoryService: InventoryService,
    private settingsService: GeneralSettingsService,
    private userConfigService: UserService,
    public dialog: MatDialog,
    private http: HttpClient,
  ) {
    this.defaultColDef = {
      suppressSizeToFit: true,
      resizable: true,
      autoHeight: true,
      sortable: true,
      filter: true,
      hide: false,
      headerComponentParams: {
        template: getHeaderTemplate(),
      },
    };
    this.headerHeight = 60;
    this.rowSelection = "multiple";
    this.frameworkComponents = {
      checkboxRenderer: CheckboxRenderer,
      disabledCheckboxRenderer: DisabledCheckboxRenderer,
      dateTimeEditor: DateTimePickerComponent,
    };

    this.getRowStyle = function (params) {
      let booked = parseBooleanValue(params.data.booked?.propertyValue);
      let goodsReady = parseBooleanValue(params.data.goodsReady?.propertyValue);
      let soReleased = parseBooleanValue(params.data.soReleased?.propertyValue);
      let containerLoaded = parseBooleanValue(params.data.containerLoaded?.propertyValue);
      let shipped = parseBooleanValue(params.data.shipped?.propertyValue);
      let dr = parseBooleanValue(params.data.documentsReceived?.propertyValue);
      let paid = parseBooleanValue(params.data.paid?.propertyValue);
      let containerNumber = params.data.containerNumber?.propertyValue;
      let shippingDate = parseDateTime(params.data.shippingDate?.propertyValue);
      let dockingDate = parseDateTime(params.data.dockingDate?.propertyValue);
      let expectedDeliverySlot = parseDateTime(
        params.data.expectedDeliverySlot?.propertyValue
      );

      if (containerNumber &&
        containerNumber.length > 4 &&
        (dockingDate < shippingDate ||
          shippingDate > expectedDeliverySlot ||
          shippingDate > dockingDate ||
          Math.abs(dateDiffInDays(expectedDeliverySlot, dockingDate)) > 90)
      ) {
        return { backgroundColor: "#F44336" };
      }

      // green
      if (paid) {
        return { backgroundColor: "#81C784" };
      }
      // orange
      if (dr) {
        return { backgroundColor: "#FFB74D" };
      }
      // red
      if (shipped) {
        return { backgroundColor: "#FF8A65" };
      }
      if (containerLoaded) {
        return { backgroundColor: "#90CAF9" };
      }
      if (soReleased) {
        return { backgroundColor: "#CFD8DC" };
      }

      if (goodsReady) {
        return { backgroundColor: "#FF3399" };
      }
      // yellow
      if (booked) {
        return { backgroundColor: "#FFF176" };
      } else {
        return { backgroundColor: "#FFFFFF" };
      }
    };
  }

  ngOnDestroy(): void {
    this.loader.hide();
    this.destroyedComponentSubj.next();
    this.destroyedComponentSubj.complete();
  }

  ngOnInit(): void {

    this.settingsService.get().pipe(
      tap((settings: GeneralSettings) => {
        this.settings = settings;
      })
    ).subscribe();
    this.localStorageObject = JSON.parse(
      localStorage.getItem(this.poScreenConfigStorageKey)
    );

    const tabsConfig = localStorage.getItem("po-screen-tabs-config");
    if (!tabsConfig) {
      // this.saveTabsConfig();
    }

    if (this.localStorageObject) {
      this.searchQuery = this.localStorageObject["search"];
      this.sortModel = this.localStorageObject["sortModel"];
      this.showUnpaid = this.localStorageObject["showUnpaid"] ?? false;
      this.periodFilters.forEach((x) => (x.isChecked = false));
      this.periodFilters.find(
        (x) => x.period === (this.localStorageObject["periodFilter"] ?? "All")
      ).isChecked = true;
    }

    this.getSuppliers();
    this.getCategories();
    this.getPorts();
    this.getPOLs();
    this.getPurchaseOrdersWithProps(this.selectedStatus);

    this.poScreenConfigSubscription();
  }

  async exportGridToExcelSheet(): Promise<void> {
    if (!this.userConfigService?.currentUserConfig?.exportToExcelAllowed) {
      return this.snackBar.warning('You dont have permissions to download Excel sheets');
    }
    let cols = this.gridColumnApi.getColumnState();
    console.table(cols);

    let headers = cols.map(this.getCol, this).filter(function (item) {
      return item && item.Field !== "#";
    });

    let rowData = [];

    this.gridApi.forEachNodeAfterFilterAndSort((node) => {
      let newNode = cloneDeep(node.data);
      for (var key in newNode) {

        if (newNode[key].hasOwnProperty('propertyValue')) {
          newNode[key] = newNode[key]['propertyValue'];
        }
      }
      rowData.push(newNode);
    });
    await this.exportToExcel(rowData, headers, 'PO_Sheet');
  }

  async exportToExcel(workbookData: any[], headers: any[], excelFileName: string): Promise<void> {
    const workbook = new ExcelJS.Workbook();

    const sheet = workbook.addWorksheet('PO Sheet');
    // const uniqueHeaders = [
    //   ...new Set<string>(
    //     rows.reduce((prev: any, next: {}) => [...prev, ...Object.keys(next)], [])
    //   )
    // ];
    sheet.columns = headers.map(x => ({ header: x.Header, key: x.Field }));

    workbookData.forEach((jsonRow: { [x: string]: any; esal: any; }, i: number) => {
      let cellValues = { ...jsonRow };

      sheet.addRow(cellValues);
      sheet.getRows(1, sheet.actualRowCount)?.forEach((row, j) => {

        if (j > 0) {
          let poNumber = row.getCell('poNumber').value;
          if (poNumber) {
            const dataNode = this.rowData.find(x => x.poNumber === row.getCell('poNumber').value);


            row.eachCell((c, n) => {
              const col = sheet.getColumn(n);

              if (col.key.toLowerCase().includes('freight') ||
                col.key.toLowerCase().includes('deposit') ||
                col.key.toLowerCase().includes('amount') ||
                col.key.toLowerCase().includes('weight')) {
                let numberValue = parseFloat(c.value.toString());
                if (numberValue && !isNaN(numberValue)) {
                  c.value = parseFloat(c.value.toString());
                  c.numFmt = '#,##0.00';
                }
              }

            });

            let booked = parseBooleanValue(dataNode.booked.propertyValue);
            let shipped = parseBooleanValue(dataNode.shipped.propertyValue);
            let dr = parseBooleanValue(dataNode.documentsReceived.propertyValue);
            let paid = parseBooleanValue(dataNode.paid.propertyValue);
            let containerNumber = dataNode.containerNumber.propertyValue;
            let shippingDate = parseDateTime(dataNode.shippingDate.propertyValue);
            let dockingDate = parseDateTime(dataNode.dockingDate.propertyValue);
            let expectedDeliverySlot = parseDateTime(dataNode.expectedDeliverySlot.propertyValue);
            let color = 'FFFFFFFF';

            if (booked) {
              color = 'FFFFF176';
            }

            if (shipped) {
              color = 'FFFF8A65';
            }

            if (dr) {
              color = 'FFFFB74D';
            }

            if (paid) {
              color = 'FF81C784';
            }

            if (containerNumber.length > 4 &&
              (dockingDate < shippingDate ||
                shippingDate > expectedDeliverySlot ||
                shippingDate > dockingDate ||
                Math.abs(dateDiffInDays(expectedDeliverySlot, dockingDate)) > 90)) {

              color = 'FFF44336';
            }

            if (color !== 'FFFFFFFF') {
              row.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: color }
              };
            }
          }
        }
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    this.saveAsExcelFile(buffer, excelFileName);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  poScreenConfigSubscription() {
    this.poScreenConfigStateSubject
      .pipe(takeUntil(this.destroyedComponentSubj))
      .subscribe(() => {
        localStorage.setItem(
          this.poScreenConfigStorageKey,
          JSON.stringify({
            search: this.searchQuery ?? "",
            category: this.selectedCategory?.categoryId ?? "",
            supplier: this.selectedSupplier?.pkSupplierID ?? "",
            sortModel: this.sortModel ?? {},
            periodFilter: this.periodFilters.find((x) => x.isChecked).period,
            showUnpaid: this.showUnpaid,
          })
        );
      });
  }

  getPurchaseOrdersWithProps(status: string) {
    let statuses = '';
    if (status === 'ALL') {
      statuses = 'PENDING,OPEN,PARTIAL,DELIVERED';
    } else {
      statuses = status;
    }

    this.rowData = [];
    combineLatest([
      this.purchaseService.getPurchasesWithExtProperties(statuses),
      this.fileService.getAllPurchasesFiles(),
    ])
      .pipe(
        takeUntil(this.destroyedComponentSubj),
        map(([purchases, files]) => {
          const purchaseGuidFilesMap = this.mapStringArrayResponse(files);
          return this.assignStatusesForPurchaseOrders(
            purchases,
            purchaseGuidFilesMap,
            "purchaseId"
          );
        })
      )
      .subscribe((purchases) => {
        this.showQuickFilter = true;
        this.baseRowData = purchases;
        if (this.selectedSupplier) {
          purchases = purchases.filter(
            (x) => x.supplierId === this.selectedSupplier.pkSupplierID
          );
        }
        if (this.selectedCategory) {
          purchases = purchases.filter((x) =>
            x.categories.includes(this.selectedCategory.categoryId)
          );
        }
        if (this.sortModel) {
          this.gridColumnApi.applyColumnState({ state: [this.sortModel] });
        }
        this.rowData && this.rowData.length > 0
          ? this.gridApi.applyTransaction({ update: [purchases] })
          : (this.rowData = purchases);

        this.defaultPeriodState = true;

        this.currentGridView = "all";
        this.gridApi.refreshCells();

        this.filteringProcess();
      });
  }

  assignStatusesForPurchaseOrders(
    purchaseOrdersParams: any[],
    purchaseGuidFileMap: Map<string, string[]>,
    extendedPropertyName: string
  ): any[] {
    const purchaseOrders: any[] = [];
    const ePropertyOrderMap = this.groupedObjectToMap(
      groupBy(purchaseOrdersParams, extendedPropertyName)
    );
    const ePropertyDocumentsMap = new Map<string, string[]>();

    ePropertyOrderMap.forEach((value, key) => {
      const currentPurchaseOrders: any[] = value;
      let files: string[] = [];
      currentPurchaseOrders.forEach((purchase) => {
        const purchaseFiles = purchaseGuidFileMap.get(purchase.purchaseId);
        if (purchaseFiles) {
          purchaseFiles.forEach((pFile) => (files = [...files, pFile]));
        }
      });
      ePropertyDocumentsMap.set(key, files);
    });

    purchaseOrdersParams.forEach((purchaseOrder) => {
      const docsForOrder = purchaseGuidFileMap.get(purchaseOrder.purchaseId);

      if (docsForOrder) {
        docsForOrder.forEach((doc) => {
          if (doc.includes(`InvoicePackingList`)) {
            purchaseOrder.documentsReceived.propertyValue = "true";
          }
        });
      }
      purchaseOrders.push(purchaseOrder);
    });
    return purchaseOrders;
  }

  private groupedObjectToMap(groupedObject: any): Map<string, string[]> {
    const groupedMap = new Map<string, string[]>();

    Object.keys(groupedObject).forEach((key) => {
      groupedObject[key].forEach(() => {
        groupedMap.set(key, groupedObject[key]);
      });
    });

    return groupedMap;
  }

  private mapStringArrayResponse(stringArray: string[]): Map<string, string[]> {
    const groupedMap = new Map<string, string[]>();
    const groupedObject = groupBy(
      stringArray,
      (item: any) => item.split("/")[0]
    );
    Object.keys(groupedObject).forEach((key) => {
      groupedObject[key].forEach(() => {
        groupedMap.set(key, groupedObject[key]);
      });
    });
    return groupedMap;
  }

  refreshPoScreen() {
    localStorage.removeItem(this.poScreenConfigStorageKey);
    this.localStorageObject = null;
    this.searchQuery = "";
    this.gridApi.showLoadingOverlay();
    this.showUnpaid = false;
    this.periodFilters.forEach((x) => (x.isChecked = false));
    this.periodFilters.find((x) => x.period === "All").isChecked = true;
    if (this.rowData.length && this.rowData.length > 0) {
      this.rowData = [];
    }
    this.selectedSupplier = null;
    this.selectedCategory = null;
    this.getSuppliers();
    this.getPorts();
    this.getPOLs();
    this.getPurchaseOrdersWithProps(this.selectedStatus);
  }

  getCol(col) {
    const selected = this.columnDefs.filter(function (def) {
      return def.colId === col.colId && !def.hide;
    })[0];
    if (selected) {
      return {
        Header: selected.headerName,
        Field: selected.field.replace(/.propertyValue/g, ""),
      };
    }
  }

  exportCurrentViewToExcelTEST() {
    let cols = this.gridColumnApi.getColumnState();
    console.table(cols);

    let headers = cols.map(this.getCol, this).filter(function (item) {
      return item && item.Field !== "#";
    });

    let rowData = [];
    this.gridApi.forEachNodeAfterFilter((node) => {
      rowData.push(node.data);
    });

    this.purchaseService
      .getExcelSheetFromCurrentPOScreenView(rowData, headers)
      .subscribe((result: any) => {
        saveFile(result, "PO_Sheet.xlsx");
      });
  }

  createColumnsDefinition() {
    this.columnDefs = [
      {
        headerName: "#",
        field: "#",
        colId: "#",
        valueGetter: 'node.data.poNumber !== "Total" ? node.rowIndex + 1 : ""',
        width: 50,
        pinned: "left",
        hide: false,
      },
      {
        headerName: "PO Number",
        field: "poNumber",
        colId: "poNumber",
        comparator: purchaseOrderNumberComparator,
        width: 105,
        pinned: "left",
        hide: false,
      },
      { field: "status", colId: "status", headerName: "Status", width: 110 },
      { field: "supplierId", colId: "pkSupplierId", hide: true },
      { field: "categories", colId: "categories", hide: true },
      {
        headerName: "Supplier",
        field: "supplierName",
        width: 120,
        colId: "supplierName",
        pinned: "left",
        hide: false,
      },
      {
        headerName: "Container Size",
        field: "containerSize.propertyValue",
        colId: "containerSize",
        editable: true,
        width: 100,
        cellEditor: "agSelectCellEditor",
        pinned: "left",
        cellEditorParams: {
          values: ["N/A", "PART", "20 FT", "40 FT", "40 FT HQ"],
        },
        hide: false,
      },
      {
        headerName: "Container Number",
        field: "containerNumber.propertyValue",
        colId: "containerNumber",
        editable: true,
        width: 120,
        pinned: "left",
        hide: false,
      },
      {
        headerName: "Favourite",
        field: "favourite",
        colId: "favourite",
        editable: false,
        width: 120,
        valueGetter: (params) =>
          parseBooleanValue(params.data.favourite),
        cellRenderer: "checkboxRenderer",
        hide: false,
      },
      {
        headerName: "Amount Column $",
        field: "amount",
        width: 100,
        valueFormatter: (params) => "$" + params.data.amount.toFixed(2),
        colId: "amount",
        hide: false,
      },
      {
        headerName: "Conversion Rate",
        field: "conversionRate",
        colId: "conversionRate",
        editable: true,
        width: 120,
        hide: false,
      },
      {
        headerName: "Seal Number",
        field: "sealNumber.propertyValue",
        colId: "sealNumber",
        editable: true,
        width: 140,
        hide: false,
      },
      {
        headerName: "Line",
        field: "line.propertyValue",
        colId: "line",
        editable: true,
        width: 140,
        hide: false,
      },
      {
        headerName: "Entry Number",
        field: "entryNumber.propertyValue",
        colId: "entryNumber",
        editable: true,
        width: 110,
        hide: false,
      },
      {
        headerName: "POL",
        field: "pol.propertyValue",
        colId: "pol",
        editable: true,
        width: 100,
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: this.polNames,
        },
        hide: false,
      },
      {
        headerName: "Vessel Name",
        field: "vesselName.propertyValue",
        colId: "vesselName",
        editable: true,
        width: 140,
        hide: false,
      },
      {
        headerName: "Forwarder",
        field: "forwarder.propertyValue",
        colId: "forwarder",
        editable: true,
        width: 110,
        hide: false,
      },
      {
        headerName: "Shipping Agent",
        field: "shippingAgent.propertyValue",
        colId: "shippingAgent",
        editable: true,
        width: 140,
        hide: false,
      },
      {
        headerName: "Port",
        field: "port.propertyValue",
        colId: "port",
        editable: true,
        width: 100,
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: this.portNames,
        },
        hide: false,
      },
      {
        headerName: "Supp Inv No",
        field: "supplierReferenceCode.propertyValue",
        colId: "supplierReferenceCode",
        editable: true,
        width: 140,
        hide: false,
      },
      {
        headerName: "Goods Ref",
        field: "reference.propertyValue",
        colId: "reference",
        editable: true,
        width: 110,
        hide: false,
      },
      {
        headerName: "Shipping Ref",
        field: "reference2.propertyValue",
        colId: "reference2",
        editable: true,
        width: 110,
        hide: false,
      },
      {
        headerName: "Goods",
        field: "stringItems",
        width: 300,
        colId: "stringItems",
        hide: false,
      },
      {
        headerName: "Total Weight",
        field: "totalWeight",
        colId: "totalWeight",
        editable: false,
        width: 135,
        valueFormatter: (params) => digitFormatter(params.data.totalWeight),
        hide: false,
      },
      {
        headerName: "Freight USD",
        field: "freightUSD.propertyValue",
        colId: "freightUSD",
        editable: true,
        width: 140,
        hide: false,
      },
      {
        headerName: "Freight",
        field: "freight.propertyValue",
        colId: "freight",
        editable: true,
        width: 140,
        hide: false,
      },
      {
        headerName: "Duty",
        field: "duty.propertyValue",
        colId: "duty",
        editable: true,
        width: 140,
        hide: false,
      },
      {
        headerName: "Order Date",
        field: "orderDateString",
        comparator: dateTimeComparator,
        width: 100,
        colId: "orderDate",
        hide: false,
      },
      {
        headerName: "Goods Ready Date",
        field: "goodsReadyDate.propertyValue",
        colId: "goodsReadyDate",
        editable: true,
        cellEditor: "dateTimeEditor",
        cellEditorPopup: true,
        cellEditorPopupPosition: 'under',
        filterParams: dateFilterParams,
        comparator: dateTimeComparator,
        width: 120,
        hide: false,
      },
      {
        headerName: "Shipping Date",
        field: "shippingDate.propertyValue",
        colId: "shippingDate",
        editable: true,
        cellEditor: "dateTimeEditor",
        cellEditorPopup: true,
        cellEditorPopupPosition: 'under',
        filterParams: dateFilterParams,
        comparator: dateTimeComparator,
        width: 100,
        hide: false,
      },
      {
        headerName: "Docking Date",
        field: "dockingDate.propertyValue",
        colId: "dockingDate",
        editable: true,
        cellEditor: "dateTimeEditor",
        cellEditorPopup: true,
        cellEditorPopupPosition: 'under',
        filterParams: dateFilterParams,
        comparator: dateTimeComparator,
        width: 100,
        cellStyle: function (params) {
          if (
            moment(params.value, "DD/MM/YY").isBefore(
              moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            )
          ) {
            return { backgroundColor: "#F48FB1" };
          } else {
            return { backgroundColor: "" };
          }
        },
        hide: false,
      },
      {
        headerName: "Expected Delivery Slot",
        field: "expectedDeliverySlot.propertyValue",
        colId: "expectedDeliverySlot",
        cellEditor: "dateTimeEditor",
        cellEditorParams: {
          allowTime: true,
        },
        cellEditorPopup: true,
        cellEditorPopupPosition: 'under',
        filterParams: dateFilterParams,
        comparator: dateTimeComparator,
        editable: true,
        width: 140,
        hide: false,
      },
      {
        headerName: "Delivery Date",
        field: "deliveryDate.propertyValue",
        colId: "deliveryDate",
        cellEditor: "dateTimeEditor",
        cellEditorParams: {
          allowTime: true,
        },
        cellEditorPopup: true,
        cellEditorPopupPosition: 'under',
        filterParams: dateFilterParams,
        comparator: dateTimeComparator,
        editable: true,
        width: 140,
        hide: false,
      },
      {
        headerName: "Disembark Time",
        field: "disembarkTime.propertyValue",
        colId: "disembarkTime",
        cellEditor: "dateTimeEditor",
        cellEditorParams: {
          allowTime: true,
        },
        cellEditorPopup: true,
        cellEditorPopupPosition: 'under',
        filterParams: dateFilterParams,
        comparator: dateTimeComparator,
        editable: true,
        width: 140,
        hide: false,
      },
      {
        headerName: "Dock Days",
        field: "dockDays.propertyValue",
        colId: "dockDays",
        editable: true,
        width: 140,
        hide: false,
      },
      {
        headerName: "Last Free Day",
        field: "lastFreeDay.propertyValue",
        colId: "lastFreeDay",
        editable: true,
        width: 140,
        hide: false,
      },
      {
        headerName: "HBL",
        field: "hbl.propertyValue",
        colId: "hbl",
        editable: true,
        width: 140,
        hide: false,
      },
      {
        headerName: "Production Time",
        field: "productionTime.propertyValue",
        colId: "productionTime",
        editable: true,
        width: 140,
        hide: false,
      },
      {
        headerName: "Stock Holding",
        field: "stockHolding.propertyValue",
        colId: "stockHolding",
        editable: true,
        width: 140,
        hide: false,
      },
      {
        headerName: "Freight Time",
        field: "freightTime.propertyValue",
        colId: "freightTime",
        editable: true,
        width: 140,
        hide: false,
      },
      {
        headerName: "Deposit",
        field: "deposit.propertyValue",
        colId: "deposit",
        editable: true,
        width: 110,
        cellStyle: function (params) {
          if (parseFloat(params.value) > 0) {
            return { backgroundColor: "#81D4FA" };
          } else {
            return { backgroundColor: "" };
          }
        },
        hide: false,
      },
      {
        headerName: "RMB Rate",
        field: "rmbRate.propertyValue",
        colId: "rmbRate",
        editable: true,
        width: 140,
        hide: false,
      },
      {
        headerName: "Archive",
        field: "archive.propertyValue",
        colId: "archive",
        editable: true,
        width: 140,
        hide: false,
      },
      {
        headerName: "Comment",
        field: "comment.propertyValue",
        colId: "comment",
        editable: true,
        width: 140,
        hide: false,
      },
      {
        headerName: "Telex",
        field: "telex.propertyValue",
        colId: "telex",
        editable: true,
        width: 140,
        hide: false,
      },
      {
        headerName: "Door Number",
        field: "doorNumber.propertyValue",
        colId: "doorNumber",
        width: 120,
        editable: true,
        hide: false,
      },
      {
        headerName: "Booked",
        field: "booked.propertyValue",
        colId: "booked",
        width: 90,
        editable: false,
        valueGetter: (params) =>
          parseBooleanValue(params.data.booked.propertyValue),
        cellRenderer: "checkboxRenderer",
        hide: false,
      },
      {
        headerName: "Goods Ready",
        field: "goodsReady.propertyValue",
        colId: "goodsReady",
        width: 90,
        editable: false,
        valueGetter: (params) =>
          parseBooleanValue(params.data.goodsReady.propertyValue),
        cellRenderer: "checkboxRenderer",
        hide: false,
      },
      {
        headerName: "SO Released",
        field: "soReleased.propertyValue",
        colId: "soReleased",
        width: 90,
        editable: false,
        valueGetter: (params) =>
          parseBooleanValue(params.data.soReleased.propertyValue),
        cellRenderer: "checkboxRenderer",
        hide: false,
      },
      {
        headerName: "Container Loaded",
        field: "containerLoaded.propertyValue",
        colId: "containerLoaded",
        width: 105,
        editable: false,
        valueGetter: (params) =>
          parseBooleanValue(params.data.containerLoaded.propertyValue),
        cellRenderer: "checkboxRenderer",
        hide: false,
      },
      {
        headerName: "Shipped",
        field: "shipped.propertyValue",
        colId: "shipped",
        width: 90,
        editable: false,
        valueGetter: (params) =>
          parseBooleanValue(params.data.shipped.propertyValue),
        cellRenderer: "checkboxRenderer",
        hide: false,
      },
      {
        headerName: "Documents Received",
        field: "documentsReceived.propertyValue",
        colId: "documentsReceived",
        editable: false,
        width: 120,
        valueGetter: (params) =>
          parseBooleanValue(params.data.documentsReceived.propertyValue),
        cellRenderer: "disabledCheckboxRenderer",
        hide: false,
      },
      {
        headerName: "Matched Docs",
        field: "matchedDocs.propertyValue",
        colId: "matchedDocs",
        editable: false,
        width: 120,
        valueGetter: (params) =>
          parseBooleanValue(params.data.matchedDocs.propertyValue),
        cellRenderer: "checkboxRenderer",
        hide: false,
      },
      {
        headerName: "Paid",
        field: "paid.propertyValue",
        colId: "paid",
        width: 80,
        editable: false,
        valueGetter: (params) =>
          parseBooleanValue(params.data.paid.propertyValue),
        cellRenderer: "checkboxRenderer",
        hide: false,
      },
      {
        headerName: "Cleared",
        field: "cleared.propertyValue",
        colId: "cleared",
        width: 110,
        editable: false,
        valueGetter: (params) =>
          parseBooleanValue(params.data.cleared.propertyValue),
        cellRenderer: "checkboxRenderer",
        hide: false,
      },
      {
        headerName: "Freight USD Paid",
        field: "freightUSDPaid.propertyValue",
        colId: "freightUSDPaid",
        width: 120,
        editable: false,
        valueGetter: (params) =>
          parseBooleanValue(params.data.freightUSDPaid.propertyValue),
        cellRenderer: "checkboxRenderer",
        hide: false,
      },
    ];
  }

  dataIsRendered() {
    this.showQuickFilter = true;
    // this.restoreState();
  }

  getPOLs() {
    this.purchaseService
      .getPOLs()
      .pipe(takeUntil(this.destroyedComponentSubj))
      .subscribe((result) => {
        this.POLs = result;
        this.polNames = this.POLs.map((x) => x.name);
        this.polNames.push("N/A");
        this.createColumnsDefinition();
        this.gridApi.setColumnDefs(this.columnDefs);
        let tabConfig = JSON.parse(
          localStorage.getItem("po-screen-tabs-config")
        );

        if (tabConfig) {
          let activeTabConfigState = tabConfig.find(
            (x) => x.tab === (this.currentGridView ?? "all").toLowerCase()
          );
          if (activeTabConfigState) {
            this.gridColumnApi.applyColumnState({
              state: activeTabConfigState.columnState,
              applyOrder: true,
            });
          }
        }
      });
  }

  getPorts() {
    this.purchaseService
      .getPorts()
      .pipe(takeUntil(this.destroyedComponentSubj))
      .subscribe((result) => {
        this.ports = result;
        this.portNames = this.ports.map((x) => x.name);
        this.portNames.push("N/A");
        this.gridApi.setColumnDefs(this.columnDefs);
      });
  }

  openPorts() {
    this.dialog.open(PortsComponent, {});
  }

  getSuppliers() {
    this.inventoryService
      .getSuppliers()
      .pipe(takeUntil(this.destroyedComponentSubj))
      .subscribe((result) => {
        this.suppliers = result;
        this.suppliers.unshift({ supplierName: "All", pkSupplierID: "" });

        if (this.localStorageObject) {
          const supplierId = this.localStorageObject["supplier"];

          if (supplierId) {
            this.selectedSupplier = this.suppliers.find(
              (x) => x.pkSupplierID === supplierId
            );
          }
        }
      });
  }

  getCategories() {
    this.inventoryService
      .getCategories()
      .pipe(takeUntil(this.destroyedComponentSubj))
      .subscribe(
        (result) => {
          this.categories = result;
          this.categories.unshift({
            categoryName: "All",
            categoryId: "",
          });

          if (this.localStorageObject) {
            const categoryId = this.localStorageObject["category"];
            if (categoryId) {
              this.selectedCategory = this.categories.find(
                (x) => x.categoryId === categoryId
              );
            }
          }
        },
        (error) => {
          console.error(error);
          this.snackBar.error("Cant load categories");
        }
      );
  }

  editPurchaseOrder(): void {
    this.poService.currentPurchase = this.selectedItem.purchaseId;
    this.poService.goBack = true;
    this.poService.returnUrl = "/po-props";
    this.router.navigate(["/home/po-view"], { queryParams: { componentIndex: this.selectedItem.poNumber } });
  }

  removePurchaseOrder(purchase: any): void {
    const dialogData = new ConfirmDialogModel(
      "Confirm Purchase Delete",
      `Are you really want to delete purchase ${purchase.poNumber}?`
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.purchaseService
          .removePurchaseOrderById(purchase.purchaseId)
          .subscribe(() => {
            this.gridApi.applyTransaction({ remove: [purchase] });
            this.snackBar.neutral("Purchase successfully deleted", "");
          });
      }
    });
  }

  setFavourite(purchase: any): void {

    let prefix = purchase.favourite ? 'Unset' : 'Set';
    const dialogData = new ConfirmDialogModel(
      "Confirm Purchase Favourite",
      `${prefix} as favourite ${purchase.poNumber}?`
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        if (!purchase.favourite) {
          this.purchaseService
            .setFavourite(purchase.purchaseId)
            .subscribe(() => {
              // this.gridApi.applyTransaction({ remove: [purchase] });
              this.snackBar.neutral("Purchase successfully marked as favourite", "");
            });
        } else {
          this.purchaseService
            .unsetFavourite(purchase.purchaseId)
            .subscribe(() => {
              // this.gridApi.applyTransaction({ remove: [purchase] });
              this.snackBar.neutral("Purchase removed from favourites", "");
            });
        }

      }
    });
  }

  downloadSelectedPDFs(): void {
    if (this.gridApi.getSelectedRows()?.length) {
      this.gridApi.getSelectedRows().forEach((x: any) => {
        this.http
        .get<Blob>(`api/po/getPurchasePDF/${x.purchaseId}`, {
          responseType: "blob" as "json",
          observe: "response",
        })
        .subscribe((response: HttpResponse<Blob>) => {
          let binaryData = [];
          binaryData.push(response.body);
          let downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(
            new Blob(binaryData, { type: "blob" })
          );
          downloadLink.setAttribute(
            "download",
            `PO_${x.poNumber}_${x.supplierName}.pdf`
          );
          document.body.appendChild(downloadLink);
          downloadLink.click();
        });
      });
    }
    
  }

  onCellValueChanged(event) {
    if (
      event.colDef.colId === "containerNumber" &&
      event.newValue.toString().length > 3
    ) {
      const purchaseOrdersWithSameContainerNumber = this.rowData.filter(
        (x) =>
          x.containerNumber.propertyValue ===
          event.newValue.toString().trim() &&
          x.poNumber !== event.data.poNumber
      );
      if (
        !event.newValue.toString().match(/([a-z]){4}[\d]{7}/gi) ||
        event.newValue.toString().length != 11
      ) {
        event.node.setDataValue(event.column, event.oldValue);
        this.snackBar.warning(
          "For container number use this pattern XXXX9999999"
        );
        return;
      }

      // if (
      //   purchaseOrdersWithSameContainerNumber &&
      //   purchaseOrdersWithSameContainerNumber.length > 0 &&
      //   event.newValue.length > 3
      // ) {
      //   event.node.setDataValue(event.column, event.oldValue);
      //   this.snackBar.warning(
      //     `Container number ${event.newValue} already assigned to another container ${purchaseOrdersWithSameContainerNumber[0].poNumber}. Please check again.`
      //   );
      //   return;
      // }
    }

    if (event.colDef.colId === "conversionRate") {
      this.updatePurchaseConversionRate(
        event.data.purchaseId,
        event.data.conversionRate
      );
    }

    if (event.colDef.colId === "favourite") {

      if (event.newValue) {
        this.purchaseService.setFavourite(event.data.purchaseId).subscribe(result => {
          this.snackBar.success("Added to favourites");
        })
      } else {
        this.purchaseService.unsetFavourite(event.data.purchaseId).subscribe(result => {
          this.snackBar.success("Removed from favourites");
        })
      }

    } else {
      let propertiesToUpdate = [event.data[event.colDef.colId]];
      // const orderedFields = [
      //   "goodsReadyDate",
      //   "shippingDate",
      //   "dockingDate",
      //   "expectedDeliverySlot",
      // ];

      // for (let index = 0; index < orderedFields.length; index++) {
      //   const element = orderedFields[index];
      //   if (
      //     event.colDef.colId === element &&
      //     index != orderedFields.length - 1
      //   ) {
      //     for (let i = index; i < orderedFields.length; i++) {
      //       const iterationField = orderedFields[i];
      //       let currentCellValue = event.data[iterationField].propertyValue;
      //       if (i != orderedFields.length - 1) {
      //         let nextCellValue: string = "";
      //         if (orderedFields[i + 1] === "expectedDeliverySlot") {
      //           const expDelSlotSplitted =
      //             event.data["expectedDeliverySlot"].propertyValue.split(" ");
      //           nextCellValue =
      //             moment(currentCellValue, "DD/MM/YY")
      //               .add(1, "days")
      //               .format("DD/MM/YY") +
      //             " " +
      //             expDelSlotSplitted[1];
      //         } else {
      //           nextCellValue = moment(currentCellValue, "DD/MM/YY")
      //             .add(1, "days")
      //             .format("DD/MM/YY");
      //         }

      //         if (
      //           moment(currentCellValue, "DD/MM/YY").isAfter(
      //             moment(
      //               event.data[orderedFields[i + 1]].propertyValue,
      //               "DD/MM/YY"
      //             )
      //           )
      //         ) {
      //           event.data[orderedFields[i + 1]].propertyValue = nextCellValue;
      //           propertiesToUpdate.push(event.data[orderedFields[i + 1]]);
      //         }
      //       }
      //     }
      //   }
      // }
      // setTimeout(() => {
      //   this.gridApi.refreshCells();
      // }, 0);


      if (event.colDef.colId === "expectedDeliverySlot") {
        let dockingDate = event.data["dockingDate"];
        let a = moment(parseDateTime(event.value)).toISOString();
        let b = moment(parseDateTime(dockingDate.propertyValue)).toISOString();
        if (moment(parseDateTime(event.value)).isBefore(moment(parseDateTime(dockingDate.propertyValue)), "day")) {
          this.snackBar.warning('Expected delivery slot cannot be before docking date. Please change that date first');
          return;
        }
      }

      // if (event.colDef.colId === "dockingDate") {
      //   //// disabled by request from Kabir 2023-11-16 !!!
      //   // let expectedDeliverySlotProperty = event.data["expectedDeliverySlot"];
      //   // expectedDeliverySlotProperty.propertyValue = moment(parseDateTime(event.value))
      //   //   .add(this.settings.intervalDockingAndExpectedDelivery, "days")
      //   //   .format("DD/MM/YY") + ' TBC';
      //   // propertiesToUpdate.push(expectedDeliverySlotProperty);
      // }

      if (event.colDef.colId === "goodsReadyDate") {

        const POL = this.POLs.find(i => i.name === event.data["pol"].propertyValue);
        debugger
        let shippingDateProperty = event.data["shippingDate"];
        shippingDateProperty.propertyValue = moment(parseDateTime(event.value))
          .add(this.settings.intervalGoodsReadyAndShipping, "days")
          .format("DD/MM/YY");
        propertiesToUpdate.push(shippingDateProperty);

        let dockingDateProperty = event.data["dockingDate"];
        dockingDateProperty.propertyValue = moment(parseDateTime(shippingDateProperty.propertyValue))
          .add(POL?.transitTime ?? this.settings.intervalShippingAndDocking, "days")
          .format("DD/MM/YY");
        propertiesToUpdate.push(dockingDateProperty);

        let expectedDeliverySlotProperty = event.data["expectedDeliverySlot"];
        expectedDeliverySlotProperty.propertyValue = moment(parseDateTime(dockingDateProperty.propertyValue))
          .add(this.settings.intervalDockingAndExpectedDelivery, "days")
          .format("DD/MM/YY") + ' TBC';
        propertiesToUpdate.push(expectedDeliverySlotProperty);
      }


      if (event.colDef.colId === "containerNumber" && event.oldValue?.length < 4) {
        event.data["shipped"].propertyValue = 'true';
        let shippedProperty = event.data["shipped"];
        shippedProperty.propertyValue = 'true';
        propertiesToUpdate.push(shippedProperty);
        this.gridApi.redrawRows();
      }
      this.gridApi.refreshCells();
      this.updateProperties(propertiesToUpdate);
    }
  }

  calculateAndUpdatePurchaseDates(): void {

    const dialogData = new ConfirmDialogModel(
      "Confirm Action",
      "Are you sure?"
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        const port = this.POLs.find(x => x.name?.toUpperCase() === this.selectedItem.pol?.propertyValue?.toUpperCase());
        this.selectedItem.shippingDate.propertyValue =
          this.generateShippingDate(
            parseDateTime(this.selectedItem.goodsReadyDate.propertyValue)
          );
        this.selectedItem.dockingDate.propertyValue = this.generateDockingDate(
          parseDateTime(this.selectedItem.shippingDate.propertyValue),
          port?.transitTime ?? this.settings.intervalShippingAndDocking
        );
        this.selectedItem.expectedDeliverySlot.propertyValue =
          this.generateExpectedDeliverySlot(
            parseDateTime(this.selectedItem.dockingDate.propertyValue),
            this.settings.intervalDockingAndExpectedDelivery
          ) + " TBC";
        try {
          this.updateProperties([
            this.selectedItem.shippingDate,
            this.selectedItem.dockingDate,
            this.selectedItem.expectedDeliverySlot,
          ]);
          this.gridApi.applyTransaction({ update: [this.selectedItem] });
        } catch {
          this.snackBar.error("Something went wrong");
        }
      }
    });
  }

  updatePurchaseConversionRate(
    purchseId: string,
    conversionRate: number
  ): void {
    let purchaseHeaderToUpdate = {
      purchaseId: purchseId,
      conversionRate: conversionRate,
    };
    this.purchaseService
      .updatePurchaseOrderHeader(purchaseHeaderToUpdate)
      .subscribe(() => {
        this.snackBar.success("Purchase updated");
      });
  }

  generatePurchaseSpecSheets(): void {
    const childIds = this.selectedItem.items.map(function (x) {
      return x.stockItemId;
    });
    let parentIds = [];

    this.inventoryService.getParentsByChilds(childIds).subscribe((result) => {
      parentIds = result;
      parentIds.forEach((i) => {
        this.inventoryService
          .getSpecSheet(i, this.selectedItem.poNumber)
          .subscribe((response: HttpResponse<Blob>) => {
            let filename: string = getFilenameFromContentDisposition(response.headers.get('Content-Disposition') || '');
            let binaryData = [];
            binaryData.push(response.body);
            let downloadLink = document.createElement("a");
            downloadLink.href = window.URL.createObjectURL(
              new Blob(binaryData, { type: "blob" })
            );
            downloadLink.setAttribute("download", filename);
            document.body.appendChild(downloadLink);
            downloadLink.click();
          });
      });
    });
  }


  sendToEmail(purchaseId: string): void {
    this.dialog.open(SelectRecepientComponent, {
      data: {
        purchaseId: purchaseId
      },
    });
  }

  setPurchaseDatesToCertainDate() {
    const dialogData = new ConfirmDialogModel(
      "Confirm Action",
      "Are you sure?"
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        let dockingDate = this.selectedItem.dockingDate;
        let shippingDate = this.selectedItem.shippingDate;
        let expectedDeliverySlot = this.selectedItem.expectedDeliverySlot;
        dockingDate.propertyValue = shippingDate.propertyValue = "01/01/20";
        expectedDeliverySlot.propertyValue =
          dockingDate.propertyValue + " 00:00";
        this.updateProperties([
          dockingDate,
          shippingDate,
          expectedDeliverySlot,
        ]);
        this.gridApi.refreshCells();
      }
    });
  }

  setPurchaseToDelivered(): void {
    const dialogData = new ConfirmDialogModel(
      "Confirm Action",
      "Are you sure?"
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.purchaseService
          .deliverAllPurchaseItems(this.selectedItem.purchaseId)
          .subscribe((result) => {
            this.refreshPoScreen();
            this.snackBar.success(
              "Container was receipted - " + this.selectedItem.poNumber
            );
          });
      }
    });
  }

  openPurchaseDocuments(): void {
    const dialogRef = this.dialog.open(PurchaseDocsComponent, {
      data: {
        purchaseId: this.selectedItem.purchaseId,
        purchaseNumber: this.selectedItem.poNumber,
        hblExtendedProperty: this.selectedItem.hbl ?? " ",
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.value) {
        this.refreshPoScreen();
      }
    });
  }

  openQcPhotosDialogComponent(): void {
    this.dialog.open(QcPhotosDialogComponent, {
      data: {
        purchaseId: this.selectedItem.purchaseId,
        purchaseNumber: this.selectedItem.poNumber,
      },
    });
  }

  openPODates(): void {
    this.dialog.open(PurchaseDatesComponent, {});
  }

  getRowData() {
    let rowData = [];
    this.gridApi.forEachNode(function (node) {
      rowData.push(node.data);
    });
    return rowData;
  }

  getRowDataAfterFilter() {
    let rows = [];
    this.gridApi.forEachNodeAfterFilterAndSort(function (rowNode, index) {
      var data = rowNode.data;
      rows.push(data);
    });
    return rows;
  }

  getRowDataAfterFilterPaid(isUnpaid: boolean) {
    let rows = [];
    this.gridApi.forEachNodeAfterFilterAndSort(function (rowNode, index) {
      if (!parseBooleanValue(rowNode.data.paid.propertyValue)) {
        rows.push(rowNode.data);
      }
    });
    return rows;
  }

  onCellClick(event) {
    if (event.colDef.colId === "poNumber") {
      this.openPurchaseOrderInfo(event);
    }
  }

  selectedSupplierChange(value): void {
    this.selectedSupplier = value;

    this.filteringProcess();

    this.poScreenConfigStateSubject.next();
  }

  selectedCategoryChange(value): void {
    // this.saveState();
    this.selectedCategory = value;

    this.filteringProcess();

    this.poScreenConfigStateSubject.next();
  }

  selectedStatusChange(value): void {
    if (value === 'ALL') {
      this.selectedStatus = 'PENDING,OPEN,PARTIAL,DELIVERED';
    } else {
      this.selectedStatus = value;
    }

    this.getPurchaseOrdersWithProps(this.selectedStatus);
  }

  openPurchaseOrderInfo(params) {
    this.dialog.open(PoInfoComponent, {
      data: {
        purchaseOrder: params.data,
        items: params.data.items,
      },
    });
  }

  updateProperties(properties): void {
    this.purchaseService.updatePurchaseProperties(properties).subscribe(() => {
      this.snackBar.success("Property updated");
    });
  }

  generateShippingDate(goodsReadyDate: any): any {
    return moment(goodsReadyDate).add(this.settings.intervalGoodsReadyAndShipping, "days").format("DD/MM/YY");
  }

  generateDockingDate(shippingDate: any, transitTime: number): any {
    return moment(shippingDate).add(transitTime, "days").format("DD/MM/YY");
  }

  generateExpectedDeliverySlot(dockingDate: any, transitTime: number): any {
    return moment(dockingDate).add(transitTime, "days").format("DD/MM/YY");
  }

  getPropertyByStringPath(object, keyPath) {
    keyPath = keyPath.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    keyPath = keyPath.replace(/^\./, ""); // strip a leading dot
    var a = keyPath.split(".");
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (object) {
        if (k in object) {
          object = object[k];
        } else {
          return;
        }
      }

    }
    return object;
  }

  onFilterTextBoxChanged(value) {
    this.searchQuery = value;

    this.filteringProcess();

    this.poScreenConfigStateSubject.next();
  }

  filteringProcess() {
    const allKeysPath = this.propertiesToArray(this.baseRowData[0]);
    let filteredArray = [];
    for (let i = 0; i < this.baseRowData.length; i++) {
      const element = this.baseRowData[i];
      let exit: boolean = false;
      for (let index = 0; index < allKeysPath.length; index++) {
        const key = allKeysPath[index];
        let prop = this.getPropertyByStringPath(element, key);
        if (
          prop &&
          !Array.isArray(prop) &&
          prop
            .toString()
            .toLowerCase()
            .includes(this.searchQuery.toString().toLowerCase())
        ) {
          filteredArray.push(element);
          exit = true;
          break;
        }
      }
      if (exit) {
        exit = false;
        continue;
      }
    }

    if (this.selectedSupplier && this.selectedSupplier.pkSupplierID) {
      filteredArray = filteredArray.filter(
        (x) => x.supplierId === this.selectedSupplier.pkSupplierID
      );
    }
    if (this.selectedCategory && this.selectedCategory.categoryId) {
      filteredArray = filteredArray.filter((x) =>
        x.categories.includes(this.selectedCategory.categoryId)
      );
    }
    const selectedDatePeriod = this.periodFilters.find(
      (x) => x.isChecked
    ).period;
    if (selectedDatePeriod !== "All") {
      let now = new Date();
      let period = new Date();

      now.setDate(now.getDate() - 1);
      if (selectedDatePeriod === "7days") {
        period.setDate(period.getDate() + 6);
      } else if (selectedDatePeriod === "14days") {
        period.setDate(period.getDate() + 13);
      } else if (selectedDatePeriod === "30days") {
        period.setDate(period.getDate() + 29);
      } else if (selectedDatePeriod === "60days") {
        period.setDate(period.getDate() + 59);
      }
      filteredArray = filteredArray.filter((x) => {
        let deliveryDate = new Date(
          parseDateTime(x.expectedDeliverySlot.propertyValue)
        );
        return deliveryDate >= now && deliveryDate < period;
      });
    }

    if (this.showUnpaid) {
      filteredArray = filteredArray.filter(
        (x) => !parseBooleanValue(x.paid.propertyValue)
      );
    }

    if (this.showGoodsReady) {
      filteredArray = filteredArray.filter(
        (x) => parseBooleanValue(x.goodsReady.propertyValue)
          && !parseBooleanValue(x.paid.propertyValue)
          && !parseBooleanValue(x.shipped.propertyValue)
          && !parseBooleanValue(x.containerLoaded.propertyValue)
          && !parseBooleanValue(x.soReleased.propertyValue)
          && !parseBooleanValue(x.documentsReceived.propertyValue)
          && !parseBooleanValue(x.booked.propertyValue)
      );
    }

    this.rowData = filteredArray;

    if (
      (this.selectedSupplier && this.selectedSupplier.pkSupplierID !== "") ||
      (this.selectedCategory && this.selectedCategory.categoryId !== "") ||
      this.periodFilters.find((x) => x.isChecked).period != "All"
    ) {
      this.addTotal();
    } else {
      this.removeTotal();
    }
  }

  propertiesToArray(obj) {
    const isObject = (val) => typeof val === "object" && !Array.isArray(val);

    const addDelimiter = (a, b) => (a ? `${a}.${b}` : b);

    const paths = (obj = {}, head = "") => {
      return Object.entries(obj).reduce((product, [key, value]) => {
        let fullPath = addDelimiter(head, key);
        return isObject(value)
          ? product.concat(paths(value, fullPath))
          : product.concat(fullPath);
      }, []);
    };

    return paths(obj);
  }

  onSortChanged(event) {
    if (this.rowData && this.rowData.length > 0) {
      this.gridApi.refreshCells();
      this.gridApi.getDisplayedRowAtIndex(0).setSelected(true);
      this.sortModel = this.gridColumnApi
        .getColumnState()
        .find((x) => x.sort !== null);
      this.poScreenConfigStateSubject.next();
    }
  }

  onFilterChanged(event) {
    this.gridApi.refreshCells();
    this.gridApi.getDisplayedRowAtIndex(0)?.setSelected(true);
  }

  filterByPaidChange(): void {
    if (this.showUnpaid) {
      this.gridColumnApi.resetColumnState();
      this.rowData = this.rowData.filter(
        (x) => !parseBooleanValue(x.paid.propertyValue)
      );
      this.rowData = orderBy(this.rowData, [
        obj => {
          const date = moment(parseDateTime(obj.dockingDateDt));
          return (date.isBefore(moment()) && !parseBooleanValue(obj.paid.propertyValue)) ? 0 : 1;
        }
      ]);
    } else {
      this.filteringProcess();
    }
    // this.poScreenConfigStateSubject.next();
  }

  filterByGoodsReadyChange(): void {
    this.filteringProcess();

    this.poScreenConfigStateSubject.next();
  }

  onSelectionChanged(event) {
    let selectedRow = this.gridApi.getSelectedRows();

    if (selectedRow) {
      this.selectedItem = selectedRow[0];
    }
  }

  removeTotal() {
    const rows = this.getRowDataAfterFilter();
    if (rows.length && rows.length > 0) {
      const totalIndex = rows.findIndex((x) => x.poNumber === "Total");
      const totalRow = rows.find((x) => x.poNumber === "Total");
      if (totalIndex != -1) {
        this.gridApi.applyTransaction({ remove: [totalRow] });
      }
    }

    this.gridApi.setPinnedBottomRowData([]);
  }

  addTotal() {
    const rows = this.rowData;

    if (!rows || rows.length === 0) {
      this.removeTotal();
      return;
    }

    let tr = <any>JSON.parse(JSON.stringify(rows[0]));
    if (rows && rows.length) {
      for (let key in tr) {
        if (tr[key].hasOwnProperty("propertyValue")) {
          tr[key] = { propertyValue: "" };
        } else {
          tr[key] = "";
        }
      }

      tr["poNumber"] = "Total";
      tr["amount"] = 0;

      rows.forEach((row) => {
        if (row.amount) {
          tr["amount"] += parseFloat(row.amount);
        }
      });
      this.gridApi.setPinnedBottomRowData([tr]);
    }
  }

  clearSearchFilter() {
    this.searchQuery = "";
    this.filteringProcess();
    this.poScreenConfigStateSubject.next();
  }

  periodFilterChanged(filter) {
    this.periodFilters.forEach((x) => (x.isChecked = false));
    filter.isChecked = true;
    this.filteringProcess();

    this.poScreenConfigStateSubject.next();
  }

  onGridReady(params: { api: any; columnApi: any }) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setHeaderHeight(40);
    this.gridApi.redrawRows();
  }

  autoSizeAll(skipHeader: boolean) {
    var allColumnIds = [];
    this.gridColumnApi
      .getAllColumns()
      .forEach(function (column: { colId: any }) {
        allColumnIds.push(column.colId);
      });
    this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  }

  resetState() {
    this.columnStateResetting = true;
    this.gridColumnApi.resetColumnState();

    setTimeout(() => {
      this.columnStateResetting = false;
    }, 1000);
  }
  //#endregion

  changeView(view): void {
    // let tabConfig = JSON.parse(localStorage.getItem("po-screen-tabs-config"));
    // let activeTabConfigState = tabConfig?.find(
    //   (x) => x.tab === view.toLowerCase()
    // );
    // if (activeTabConfigState) {
    //   // let activeTabConfigState = tabConfig.find(
    //   //   (x) => x.tab === view.toLowerCase()
    //   // );
    //   if (activeTabConfigState) {
    //     this.gridColumnApi.applyColumnState({
    //       state: activeTabConfigState.columnState,
    //       applyOrder: true,
    //     });
    //   }


    // } else {
    this.createColumnsDefinition();

    const viewsDefs = {
      numbers: [
        "#",
        "poNumber",
        "pkSupplierId",
        // "categories",
        "supplierName",
        "containerNumber.propertyValue",
        "conversionRate",
        "hbl.propertyValue",
        "sealNumber.propertyValue",
        "containerSize.propertyValue",
        "line.propertyValue",
        "entryNumber.propertyValue",
      ],
      vessels: [
        "#",
        "poNumber",
        "pkSupplierId",
        // "categories",
        "supplierName",
        "containerNumber.propertyValue",
        "pol.propertyValue",
        "vesselName.propertyValue",
        "forwarder.propertyValue",
        "port.propertyValue",
      ],
      dates: [
        "#",
        "poNumber",
        "supplierName",
        "pkSupplierId",
        // "categories",
        "containerNumber.propertyValue",
        "vesselName.propertyValue",
        "orderDateString",
        "goodsReadyDate.propertyValue",
        "shippingDate.propertyValue",
        "dockingDate.propertyValue",
        "expectedDeliverySlot.propertyValue",
        "deliveryDate.propertyValue",
        "disembarkTime.propertyValue",
        "dockDays.propertyValue",
        "lastFreeDay.propertyValue",
        "paid.propertyValue",
      ],
      docs: [
        "#",
        "poNumber",
        "supplierName",
        "pkSupplierId",
        // "categories",
        "containerNumber.propertyValue",
        "vesselName.propertyValue",
        "booked.propertyValue",
        "goodsReady.propertyValue",
        "soReleased.propertyValue",
        "containerLoaded.propertyValue",
        "shipped.propertyValue",
        "documentsReceived.propertyValue",
        "paid.propertyValue",
        "matchedDocs.propertyValue",
        "telex.propertyValue",
      ],
      freightCost: [
        "#",
        "poNumber",
        "supplierName",
        "pkSupplierId",
        // "categories",
        "containerNumber.propertyValue",
        "amount",
        "conversionRate",
        "freightUSD.propertyValue",
        "freight.propertyValue",
        "duty.propertyValue",
        "shippingAgent.propertyValue",
      ],
      production: [
        "#",
        "poNumber",
        "supplierName",
        "pkSupplierId",
        // "categories",
        "containerNumber.propertyValue",
        "productionTime.propertyValue",
        "stockHolding.propertyValue",
        "freightTime.propertyValue",
      ],
    };

    let specificViewColumnDefs = [];

    if (view === "all") {
      this.createColumnsDefinition();
      this.gridApi.setColumnDefs(this.columnDefs);
      this.gridColumnApi.resetColumnState();
      return;
    }

    const selectedView = viewsDefs[view];
    this.columnDefs.forEach(colDef => {
      const vc = selectedView.find(x => x === colDef.field);
      if (vc) {
        colDef.hide = false;
      } else {
        colDef.hide = true;
      }
    });
    // viewsDefs[view].forEach((c) => {
    //   let vc = this.columnDefs.find((x) => x.field === c);
    //   if (vc) {
    //     specificViewColumnDefs.push(vc);
    //   } else {
    //     vc['hide'] = true;
    //     specificViewColumnDefs.push(vc);
    //   }
    // });
    // this.columnDefs = specificViewColumnDefs;
    // }
  }


  saveTabsConfig() {
    if (!this.currentGridView) {
      return;
    }
    let colIds = this.columnDefs.map((x) => x.colId);
    let columnsState = this.gridColumnApi
      .getColumnState()
      .filter((x) => !x.hide && colIds.includes(x.colId));

    let tabConfig = JSON.parse(localStorage.getItem("po-screen-tabs-config"));
    if (tabConfig) {
      let activeTabConfig = tabConfig.find(
        (x) => x.tab === this.currentGridView
      );
      if (activeTabConfig) {
        activeTabConfig.columnState = columnsState;
      } else {
        tabConfig.push({
          tab: this.currentGridView,
          columnState: columnsState,
        });
      }
      localStorage.setItem("po-screen-tabs-config", JSON.stringify(tabConfig));
    } else {
      localStorage.setItem(
        "po-screen-tabs-config",
        JSON.stringify([
          { tab: this.currentGridView, columnState: columnsState },
        ])
      );
    }
  }
}

var dateFilterParams = {
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    var cellDate = parseDateTime(cellValue);
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
};

function digitFormatter(value) {
  if (!value || isNaN(value)) {
    return;
  }

  return value
    .toFixed(2)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
