import { Component, OnInit, Inject, ViewChild, OnDestroy } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { HttpClient } from "@angular/common/http";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { DatePipe } from "@angular/common";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { IntakeDialogComponent } from "./intake-dialog/intake-dialog.component";
import { LastWeeksSalesComponent } from "./last-weeks-sales/last-weeks-sales.component";
import { ForecastOverrideComponent } from "./forecast-override/forecast-override.component";
import { MatAccordion, MatExpansionPanel } from "@angular/material/expansion";
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from "../../shared/components/confirm-dialog/confirm-dialog.component";
import { EditProcurementItemPropsComponent } from "./edit-procurement-item-props/edit-procurement-item-props.component";
import { TimePeriodForecastComponent } from "./time-period-forecast/time-period-forecast.component";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";
import { UntypedFormControl } from "@angular/forms";
import { InventoryService } from "src/app/shared/services/inventory.service";
import { Subject } from "rxjs";
import { Category } from "src/app/shared/models/category";
import { Supplier } from "src/app/shared/models/Supplier";
import { POItemsBySupplierService } from "../po-creation-tab/po-creation/po-items.service";
import { Router } from "@angular/router";
import * as _ from 'lodash-es';
import * as moment from 'moment';
import { POService } from "../po-creation-tab/po-view/po.service";

export interface Procurement {
  sku: string;
  title: string;
  stockItemId: string;
  stockLevel: number;
  stockOnOrder: number;
  soldStatsLast8Weeks: number;
  yearlySales: number;
  intake: number;
  details: ProcurementDetails[];
  cbm: number;
  cartonQty: number;
  childItems?: Procurement[];
  isHidden: boolean;
  purchaseOrdersIds: string[];
  discontinued: boolean;
}

export interface ItemToPO {
  sku: string;
  stockItemId: string;
  title: string;
  qty: number;
  cbm: number;
  childItems?: Procurement[];
  weekIndex?: number;
}

interface ProcurementDetails {
  description: string;
  weekInfo: { [key: string]: number };
  detailStatus: number[];
  forecastQty: number[];
}

interface Column {
  name: string;
  displayedName: string;
  date: Date;
}

interface SelectedItem {
  item?: Procurement;
  index?: number;
}

export interface SuggestedItem {
  stockItemId: string;
  cbm: number;
  quantity: number;
}

@Component({
  selector: "app-procurement-new",
  templateUrl: "./procurement-new.component.html",
  styleUrls: ["./procurement-new.component.scss"],
  providers: [DatePipe],
})
export class ProcurementNewComponent implements OnInit, OnDestroy {
  @ViewChild(MatAccordion) accordion: MatAccordion;

  public displayedColumns: Column[] = [
    { name: "stockLevel", displayedName: "Stock Level", date: null },
    { name: "stockOnOrder", displayedName: "On Order", date: null },
    { name: "yearlySales", displayedName: "Yearly Sales", date: null },
    { name: "description", displayedName: "", date: null },
  ];

  public columnsForecast: Column[] = [];
  public columnsToDisplay: string[] = this.displayedColumns.map((col) => col.name);
  public columnsForecastToDisplay: string[];
  public columnsToDisplayMain = ["sku", "title"];
  public expandedElement: Procurement | null;
  public suppliers: Supplier[];
  public categories: Category[];
  public selectedSupplier: Supplier;
  public selectedCategory: Category;
  public horizontalPosition: MatSnackBarHorizontalPosition = "center";
  public verticalPosition: MatSnackBarVerticalPosition = "top";
  public weekPlannedCBM = 0;
  public weekPlannedQty = 0;
  public weekPlannedNumber: number[][];
  public selectedColumn: boolean[];
  public procurementData: Procurement[];
  public procurementDataOrigin: Procurement[];
  public pagedList: Procurement[];
  public pageSize: number = 20;
  public itemsToPO: ItemToPO[];
  public tempItemToPO: ItemToPO;
  public selectedItem: SelectedItem = {};
  public forecastToOverride: number;
  public loadingData = false;
  public hideDownloadButton = true;
  public dataSource = new MatTableDataSource<any>();
  public http: HttpClient;
  public baseUrl: string;
  public startIndex: number;
  public endIndex: number;
  public selectedWeek = 0;
  public destroyedComponentSubj = new Subject<void>();
  public searchItemsCtrl = new UntypedFormControl();
  public filteredItems: any;
  public searchItemField: string;
  public shippedOnly: boolean = false;
  public weeklySuggestions: { weekIndex: number; weekStart: Date, items: SuggestedItem[] }[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private _snackBar: MatSnackBar,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private router: Router,
    private inventoryService: InventoryService,
    private purchaseCreationService: POItemsBySupplierService,
    private purchaseService: POService,
  ) {
    this.http = http;
    this.baseUrl = baseUrl;

    this.selectedColumn = new Array<boolean>(40);
    this.itemsToPO = new Array<ItemToPO>();
  }

  ngOnInit(): void {

    this.loadingData = true;

    this.inventoryService
      .getSuppliersForProductScreen()
      .pipe(takeUntil(this.destroyedComponentSubj))
      .subscribe((result) => {
        this.suppliers = result.filter((x) => x.active);
        this.loadingData = false;
      });

    this.searchItemsCtrl.valueChanges
      .pipe(
        debounceTime(500),
        filter((term) => term && term.toString().trim().length > 0),
        distinctUntilChanged(),
        tap(() => {
          this.filteredItems = [];
          this.loadingData = true;
        }),
        takeUntil(this.destroyedComponentSubj),
        switchMap((value) =>
          this.http
            .get(this.baseUrl + "api/inventory/getStockItemsByKey/" + value)
            .pipe(
              finalize(() => {
                this.loadingData = false;
              })
            )
        )
      ).subscribe((data) => {
        if (data == undefined) {
          this.filteredItems = [];
        } else {
          this.filteredItems = data;
        }
      });

    this.purchaseService.purchaseOrderCreatedSubject
      .pipe(
        tap((value: boolean) => {
          if (value) {
            this.getProcurementData(
              this.selectedSupplier.pkSupplierID,
              this.selectedCategory.categoryId
            );
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyedComponentSubj.next();
    this.destroyedComponentSubj.complete();
  }

  public onlyShippedChange(): void {
    this.procurementData = [];
    this.procurementDataOrigin = [];
    this.pagedList = [];
    this.weekPlannedCBM = 0;
    this.weekPlannedQty = 0;
    this.selectedItem.index = -2;
    this.getProcurementData(
      this.selectedSupplier.pkSupplierID,
      this.selectedCategory.categoryId
    );
  }

  selectedFiltereditemChange(params) {
    let selectedItem = this.filteredItems.find((x) => x.sku == params.value);
    // this.selectedSupplier = {supplierName: selectedItem.supplier, pkSupplierID: '%'};
    // this.selectedCategory = {categoryId: '%', categoryName: selectedItem.category};
    this.selectedCategory = null;
    this.selectedSupplier = null;
    this.selectedItem.index = -2;
    this.getProcurementData("all", "all", selectedItem.sku);
  }

  getProcurementData(
    supplierId: string = "all",
    categoryId: string = "all",
    stockItemId: string = "all",
  ): void {
    this.loadingData = true;

    // Clear selected item fields
    this.procurementData = [];
    this.procurementDataOrigin = [];
    this.pagedList = [];

    // Get procurement data
    this.http
      .get<any[]>(
        this.baseUrl +
        "api/procurement/procurementData/" +
        supplierId +
        "/" +
        categoryId +
        "/" +
        stockItemId +
        "/" + this.shippedOnly
      )
      .pipe(takeUntil(this.destroyedComponentSubj))
      .subscribe((result) => {
        if (result.length > 0) {
          this.procurementData = result;
          // this.procurementDataOrigin = JSON.parse(JSON.stringify(result));
          this.fillForecastColumns();
          this.dataSource = new MatTableDataSource<any>(this.procurementData);
          this.pagedList = this.procurementData.slice(0, this.pageSize);

          this.weekPlannedNumber = new Array<Array<number>>();

          for (let i = 0; i <= result.length; i++) {
            this.weekPlannedNumber[i] = [];
            for (let j = 0; j <= 40; j++) {
              this.weekPlannedNumber[i][j] = 0;
            }
          }

          this.procurementData.forEach((element) => {
            for (let i = 0; i < 8; i++) {
              for (let k = 0; k < 40; k++) {
                // Inventory
                if (i === 2) {
                  if (k === 0) {
                    const ti =
                      element.stockLevel + element.details[3].weekInfo[k];
                    element.details[i].weekInfo[k] = ti < 0 ? 0 : ti;
                    element.details[6].weekInfo[k] = ti < 0 ? 0 : ti;
                  } else {
                    const ti =
                      element.details[i].weekInfo[k - 1] +
                      element.details[3].weekInfo[k] -
                      element.soldStatsLast8Weeks;
                    element.details[i].weekInfo[k] = ti < 0 ? 0 : ti;
                    element.details[6].weekInfo[k] = ti < 0 ? 0 : ti;
                  }
                }

                // Weeks Cover
                if (i === 4) {
                  for (let y = 0; y < 40; y++) {
                    const f =
                      element.details[1].weekInfo[y] <= 0
                        ? element.details[0].weekInfo[y]
                        : element.details[1].weekInfo[y];
                    let wc = isNaN(
                      Math.round(element.details[2].weekInfo[y] / f)
                    )
                      ? 0
                      : Math.round(element.details[2].weekInfo[y] / f);
                    wc = isFinite(wc) ? wc : 0;

                    element.details[4].weekInfo[y] = wc;
                    element.details[7].weekInfo[y] = wc;
                  }
                }
              }
            }
            for (let i = 0; i < this.pagedList.length; i++) {
              this.recalculateTableValues(i);
            }
          });

          this.procurementDataOrigin = JSON.parse(
            JSON.stringify(this.procurementData)
          );

          this.dataSource.paginator = this.paginator;
          this.selectedItem.item = this.pagedList[0];
          this.selectedItem.index = 0;
        } else {
          this.openSnackBar("No data found", "");
        }
        this.loadingData = false;
      });
  }

  // Paginator
  OnPageChange(event: PageEvent) {
    this.startIndex = event.pageIndex * event.pageSize;
    this.endIndex = this.startIndex + event.pageSize;
    if (this.endIndex > this.procurementData.length) {
      this.endIndex = this.procurementData.length;
    }
    this.pagedList = this.procurementData.slice(this.startIndex, this.endIndex);
  }

  // Calculate forecast (override for 20 weeks)
  fillForecastColumns(): void {
    this.columnsForecast = new Array<Column>(40);
    for (let i = 0; i < 40; i++) {
      const date = new Date();
      const formattedDate = this.datepipe.transform(
        date.setDate(date.getDate() + i * 7),
        "dd/MM"
      );
      this.columnsForecast[i] = {
        name: i.toString(),
        displayedName: formattedDate + " - " + (i + 1).toString(),
        date: moment().add(i * 7, 'day').toDate(),
      };
    }
    this.columnsForecastToDisplay = this.columnsForecast.map((col) => col.name);
  }

  updateForecast(date: Date, value: any, index: number) {
    if ((value === null || value === undefined) && value !== 0 ) return;
    let forecastToUpdate = {
      stockItemId: this.selectedItem.item.stockItemId,
      forecast: this.selectedItem.item.details[1].weekInfo,
      supplierId: this.selectedSupplier.pkSupplierID,
      forecastValue: value,
      weekIndex: index,
      date: date,
    };
    this.http
      .post(
        this.baseUrl +
        "api/procurement/saveProcurementForecast/" +
        this.selectedItem.item.stockItemId,
        forecastToUpdate
      )
      .pipe(
        takeUntil(this.destroyedComponentSubj)
      )
      .subscribe();
  }

  selectedSupplierChange(value): void {
    // Clear scope data
    this.procurementData = [];
    this.procurementDataOrigin = [];
    this.pagedList = [];
    this.weekPlannedCBM = 0;
    this.weekPlannedQty = 0;
    this.selectedItem.index = -2;
    this.loadingData = true;

    // Get categories
    this.http
      .get<Category[]>(
        this.baseUrl +
        "api/inventory/categories/" +
        this.selectedSupplier.pkSupplierID
      )
      .pipe(takeUntil(this.destroyedComponentSubj))
      .subscribe((result) => {
        this.categories = result;
        this.categories.unshift({ categoryId: "all", categoryName: "All" });
        this.loadingData = false;
      });
  }

  selectedCategoryChange(value): void {
    // Clear scope data
    this.procurementData = [];
    this.procurementDataOrigin = [];
    this.pagedList = [];
    this.weekPlannedCBM = 0;
    this.weekPlannedQty = 0;
    this.selectedItem.index = -2;
    this.getProcurementData(
      this.selectedSupplier.pkSupplierID,
      this.selectedCategory.categoryId
    );
  }

  // Get purchase order detail for specific item and week
  getIntake(index, row, cell, value, $event): void {
    $event.stopPropagation();
    if (value > 0) {
      const dialogRef = this.dialog.open(IntakeDialogComponent, {
        data: {
          stockItem: this.pagedList[index],
          weekNumber: cell,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (!result) {
          return;
        }

        if (result.value === true) {
          this.getProcurementData(
            this.selectedSupplier.pkSupplierID,
            this.selectedCategory.categoryId
          );
        }
      });
    }
  }

  openTimePeriodForecast() {
    this.dialog.open(TimePeriodForecastComponent, {
      data: {
        items: this.procurementData,
        supplierId: this.selectedSupplier.pkSupplierID,
      },
    });
  }

  hideProcurementItem(item: Procurement) {
    let message = "";
    if (!item.isHidden) {
      message = `Are you sure want to hide this item?`;
    } else {
      message = `Are you sure want to unhide this item?`;
    }
    const dialogData = new ConfirmDialogModel("Confirm Action", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        let hide = false;
        if (item.isHidden && dialogResult) {
          hide = false;
        }
        if (!item.isHidden && dialogResult) {
          hide = true;
        }
        this.loadingData = true;
        this.http
          .put(
            this.baseUrl +
            "api/procurement/hideProcurementItem/" +
            item.stockItemId +
            "/" +
            hide,
            {}
          )
          .subscribe(
            (result) => {
              this.loadingData = false;
              item.isHidden = hide;
              this.openSnackBar("Succesfully updated", "");
            },
            (error) => {
              this.loadingData = false;
              this.openSnackBar("Something went wrong (", "");
            }
          );
      }
    });
  }

  clearOutcome() {
    const index = this.selectedItem.index;
    for (let i = 0; i < 40; i++) {
      this.pagedList[index].details[6].weekInfo[i] = 0;
      this.pagedList[index].details[7].weekInfo[i] = 0;
    }
  }

  resetCalculations() {
    this.pagedList = this.procurementDataOrigin.slice(0, 10);
  }

  selectSKU(index: number): void {
    this.selectedItem.item = this.pagedList[index];
    this.selectedItem.index = index;
  }

  getLastWeekSales(): void {
    let tempStockItemId = "";
    tempStockItemId = this.selectedItem.item.stockItemId;

    this.dialog.open(LastWeeksSalesComponent, {
      data: {
        stockItemId: tempStockItemId,
        selectedItem: this.selectedItem.item,
        items: this.procurementData,
      },
    });
  }

  openPurchaseOrderDialog(): void {
    if (this.selectedSupplier) {
      this.purchaseCreationService.procurementSupplierId = this.selectedSupplier.pkSupplierID;
      this.purchaseCreationService.purchaseDate.setDate(new Date().getDate() + this.selectedWeek * 7);

      this.itemsToPO.forEach(i => {
        if (i.childItems && i.childItems.length > 0) {
          i.childItems.forEach(c => {
            this.purchaseCreationService.procurementPurchaseItems.push({
              stockItemID: c.stockItemId,
              quantity: i.qty
            });
          });
        } else {
          this.purchaseCreationService.procurementPurchaseItems.push({
            stockItemID: i.stockItemId,
            quantity: i.qty
          });
        }
      })
    }
    this.router.navigate(["/po-wrapper/po-creation"]);
  }

  openForecastOverrideDialog(): void {
    if (this.selectedSupplier) {
      const dialogRef = this.dialog.open(ForecastOverrideComponent, {});

      dialogRef.afterClosed().subscribe((result) => {
        if (result.value !== null) {
          this.forecastToOverride = result.value;
          this.overrideForecast();
          for (let i = 0; i < this.columnsForecast?.length; i++) {
            this.updateForecast(this.columnsForecast[i].date, result.value, i);
          }
        }
      });
    }
  }

  openExtendedPropsEditDialog(): void {
    if (this.selectedItem) {
      const dialogRef = this.dialog.open(EditProcurementItemPropsComponent, {
        data: {
          stockItemId: this.selectedItem.item.stockItemId,
          sku: this.selectedItem.item.sku,
        },
      });
    }
  }

  // Week (column in table) selection for calc and PO generation
  selectColumn(itemIndex: number, weekIndex: number): void {
    this.selectedWeek = weekIndex;

    for (let z = 0; z < 40; z++) {
      if (z === weekIndex) {
        this.selectedColumn[z] = true;
      } else {
        this.selectedColumn[z] = false;
      }
    }

    this.weekPlannedNumber[itemIndex][weekIndex] =
      this.pagedList[itemIndex].details[5].weekInfo[weekIndex];

    let tempWeekPlannedCBM = 0;
    let tempWeekPlannedQty = 0;
    for (let i = 0; i < this.procurementDataOrigin.length; i++) {
      tempWeekPlannedCBM +=
        (this.procurementDataOrigin[i].details[5].weekInfo[weekIndex] *
          this.procurementDataOrigin[i].cbm) /
        (this.procurementDataOrigin[i].cartonQty > 0
          ? this.procurementData[i]?.childItems?.length ? 1 :
            this.procurementDataOrigin[i].cartonQty
          : 1);
      tempWeekPlannedQty +=
        this.procurementDataOrigin[i].details[5].weekInfo[weekIndex];
    }
    this.weekPlannedCBM = parseFloat(tempWeekPlannedCBM.toFixed(2));
    this.weekPlannedQty = tempWeekPlannedQty;

    let titpo: ItemToPO = {
      sku: this.procurementDataOrigin[itemIndex].sku,
      stockItemId: this.procurementDataOrigin[itemIndex].stockItemId,
      title: this.procurementDataOrigin[itemIndex].title,
      cbm: (this.procurementDataOrigin[itemIndex].details[5].weekInfo[weekIndex] / (this.procurementDataOrigin[itemIndex].cartonQty > 0
        ? this.procurementData[itemIndex]?.childItems?.length ? 1 :
          this.procurementDataOrigin[itemIndex].cartonQty
        : 1)) * this.procurementDataOrigin[itemIndex].cbm,
      qty: this.procurementDataOrigin[itemIndex].details[5].weekInfo[weekIndex],
      childItems: this.procurementDataOrigin[itemIndex].childItems,
      weekIndex: weekIndex
    };

    const index = this.itemsToPO.findIndex(
      (i) => i.stockItemId === titpo.stockItemId && i.weekIndex === weekIndex
    );

    if (index === -1) {
      this.itemsToPO.push(titpo);
    } else {
      this.itemsToPO[index] = titpo;
    }
  }

  preventCollapse(event: Event, panel: MatExpansionPanel): void {
    // Check if the panel is expanded or collapsed
    if (panel.expanded) {
      // Prevent default collapse behavior
      event.stopPropagation();
    } else {
      // If the panel is not expanded, allow it to expand
      panel.open();
    }
  }

  markAsDiscontinued(event: any, item: Procurement): void {
    // if (this.selectedItem) {
    //   const dialogData = new ConfirmDialogModel(
    //     "Confirm marking as Discontinued",
    //     `Are you really want to mark this item ${this.selectedItem.item.sku}?`
    //   );
    //   const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    //     maxWidth: "400px",
    //     data: dialogData,
    //   });
    //   dialogRef.afterClosed().subscribe((dialogResult) => {
    //     if (dialogResult) {
    //       const discontinuedExtProperty = {
    //         fkStockItemId: this.selectedItem.item.stockItemId,
    //         properyName: "DISCONTINUED",
    //         propertyValue: value === true ? "1" : "0",
    //       };

    //       this.inventoryService
    //         .updatetStockItemExtendedProperty(discontinuedExtProperty)
    //         .subscribe((result) => {
    //           this.openSnackBar(
    //             "Successfully marked as discontinued",
    //             this.selectedItem.item.sku
    //           );
    //         });
    //     }
    //   });
    // }

    event.stopPropagation();

    const discontinuedExtProperty = {
      fkStockItemId: item.stockItemId,
      properyName: "Discontinued",
      propertyValue: item.discontinued ? "0" : "1",
    };

    this.inventoryService
      .updatetStockItemExtendedProperty(discontinuedExtProperty)
      .subscribe((result) => {
        this.openSnackBar(
          item.discontinued ? "Item is active now" : "Successfully marked as discontinued",
          this.selectedItem.item.sku
        );
        item.discontinued = !item.discontinued;
      });
  }



  overrideForecast(): void {
    if (this.forecastToOverride > -1) {
      const index = this.selectedItem.index;
      for (let i = 0; i < 40; i++) {
        this.pagedList[this.selectedItem.index].details[1].weekInfo[i] =
          this.forecastToOverride;
      }

      this.recalculateTableValues(index);
    }
  }

  onCellValueChange(index, row, cell, value, description): void {
    const cellIndex: number = parseInt(cell);

    if (description === "Suggestion") {
      this.pagedList[index].details[6].weekInfo[cell] = this.procurementDataOrigin[index].details[6].weekInfo[cell] + value;
      this.procurementDataOrigin[index].details[5].weekInfo[cell] = value;

      if (
        this.pagedList[index].details[0].weekInfo[cellIndex] > 0 &&
        this.pagedList[index].details[6].weekInfo[cellIndex] /
        this.pagedList[index].details[0].weekInfo[cellIndex] >
        -1
      ) {
        this.pagedList[index].details[7].weekInfo[cellIndex] = Math.round(
          this.pagedList[index].details[6].weekInfo[cellIndex] /
            this.pagedList[index].details[1].weekInfo[cellIndex] > 0 ? this.pagedList[index].details[1].weekInfo[cellIndex] : this.pagedList[index].details[0].weekInfo[cellIndex]
        );
      } else {
        this.pagedList[index].details[7].weekInfo[cellIndex] = 0;
      }

      for (let i = cellIndex + 1; i < 40; i++) {
        let itemConsumption = (this.pagedList[index].details[1].weekInfo[i] > 0 ? this.pagedList[index].details[1].weekInfo[i] : this.pagedList[index].details[0].weekInfo[i]);
        // this.pagedList[index].details[1].weekInfo[cellIndex] > 0
        //   ? this.pagedList[index].details[1].weekInfo[cellIndex]
        //   : this.pagedList[index].details[0].weekInfo[cellIndex];
        if (this.pagedList[index].details[6].weekInfo[i - 1] - itemConsumption > 0) {
          this.pagedList[index].details[6].weekInfo[i] = this.pagedList[index].details[6].weekInfo[i - 1] + this.pagedList[index].details[3].weekInfo[i] - (this.pagedList[index].details[1].weekInfo[i] > 0 ? this.pagedList[index].details[1].weekInfo[i] : this.pagedList[index].details[0].weekInfo[i]);
        } else {
          this.pagedList[index].details[6].weekInfo[i] = 0;
        }
        if (
          itemConsumption > 0
        ) {
          this.pagedList[index].details[7].weekInfo[i - 1] = Math.round(this.pagedList[index].details[6].weekInfo[i - 1] / (this.pagedList[index].details[1].weekInfo[i - 1] > 0 ? this.pagedList[index].details[1].weekInfo[i - 1] : this.pagedList[index].details[0].weekInfo[i - 1]));
        } else {
          this.pagedList[index].details[7].weekInfo[i] = 0;
        }
      }

      this.selectColumn(index, parseInt(cell));

      //
      const procurementItem = this.procurementDataOrigin[index];
      let ws = this.weeklySuggestions.find(i => i.weekIndex === cellIndex + 1);
      const wsIndex = this.weeklySuggestions.indexOf(ws);
      if (ws) {
        if (ws.items && ws.items.length > 0) {
          const suggestedItem = ws.items.find(i => i.stockItemId === procurementItem.stockItemId);
          if (suggestedItem) {
            const suggestedItemIndex = ws.items.indexOf(suggestedItem);
            ws.items[suggestedItemIndex].quantity = this.procurementDataOrigin[index].details[5].weekInfo[cell];
            ws.items[suggestedItemIndex].cbm = procurementItem.cbm / procurementItem.cartonQty;
          } else {
            ws.items.push({
              stockItemId: procurementItem.stockItemId,
              cbm: procurementItem.cbm / procurementItem.cartonQty,
              quantity: this.procurementDataOrigin[index].details[5].weekInfo[cell]
            } as SuggestedItem
            );
          }
          if (this.getWeeklySuggestionsSummary(wsIndex)[0] === 0) {
            this.weeklySuggestions.splice(wsIndex, 1);
          }
        }
      } else {
        this.weeklySuggestions.push(
          {
            weekIndex: cellIndex + 1,
            weekStart: moment().add('day', this.selectedWeek * 7).toDate(),
            items: [{
              stockItemId: procurementItem.stockItemId,
              cbm: procurementItem.cbm / procurementItem.cartonQty,
              quantity: this.procurementDataOrigin[index].details[5].weekInfo[cell]
            }]
          }
        );
      }
      this.weeklySuggestions = _.sortBy(this.weeklySuggestions, ['weekIndex'], 'asc');

    }

    if (description === "Forecast") {
      if (value > 0) {
        this.pagedList[index].details[1].weekInfo[cell] = value;
      } else {
        this.pagedList[index].details[1].weekInfo[cell] = 0;
      }
      this.recalculateTableValues(index);
    }
  }

  // onCellValueChange(index, row, cell, value, description): void {
  //   const cellIndex: number = parseInt(cell);

  //   if (description === "Suggestion") {
  //     this.pagedList[index].details[6].weekInfo[cell] = this.pagedList[index].details[6].weekInfo[cell] + value;
  //     this.pagedList[index].details[5].weekInfo[cell] = value;

  //     if (
  //       this.pagedList[index].details[0].weekInfo[cellIndex] > 0 &&
  //       this.pagedList[index].details[6].weekInfo[cellIndex] /
  //       this.pagedList[index].details[0].weekInfo[cellIndex] >
  //       -1
  //     ) {
  //       this.pagedList[index].details[7].weekInfo[cellIndex] = Math.round(
  //         this.pagedList[index].details[6].weekInfo[cellIndex] /
  //         this.pagedList[index].details[0].weekInfo[cellIndex]
  //       );
  //     } else {
  //       this.pagedList[index].details[7].weekInfo[cellIndex] = 0;
  //     }

  //     for (let i = cellIndex + 1; i < 40; i++) {
  //       let itemConsumption =
  //         this.pagedList[index].details[1].weekInfo[cellIndex] > 0
  //           ? this.pagedList[index].details[1].weekInfo[cellIndex]
  //           : this.pagedList[index].details[0].weekInfo[cellIndex];
  //       if (this.pagedList[index].details[6].weekInfo[i - 1] - itemConsumption > 0) {
  //         this.pagedList[index].details[6].weekInfo[i] = this.pagedList[index].details[6].weekInfo[i - 1] - itemConsumption;
  //       } else {
  //         this.pagedList[index].details[6].weekInfo[i] = 0;
  //       }
  //       if (
  //         itemConsumption > 0 && this.pagedList[index].details[6].weekInfo[i] / itemConsumption > -1
  //       ) {
  //         this.pagedList[index].details[7].weekInfo[i - 1] = Math.round(
  //           this.pagedList[index].details[6].weekInfo[i - 1] / itemConsumption
  //         );
  //       } else {
  //         this.pagedList[index].details[7].weekInfo[i] = 0;
  //       }
  //     }
  //     this.selectColumn(index, parseInt(cell));

  //     //
  //     const procurementItem = this.pagedList[index];
  //     let ws = this.weeklySuggestions.find(i => i.weekIndex === cellIndex + 1);
  //     const wsIndex = this.weeklySuggestions.indexOf(ws);
  //     if (ws) {
  //       if (ws.items && ws.items.length > 0) {
  //         const suggestedItem = ws.items.find(i => i.stockItemId === procurementItem.stockItemId);
  //         if (suggestedItem) {
  //           const suggestedItemIndex = ws.items.indexOf(suggestedItem);
  //           ws.items[suggestedItemIndex].quantity = this.pagedList[index].details[5].weekInfo[cell];
  //           ws.items[suggestedItemIndex].cbm = procurementItem.cbm / procurementItem.cartonQty;
  //         } else {
  //           ws.items.push({
  //               stockItemId: procurementItem.stockItemId,
  //               cbm: procurementItem.cbm / procurementItem.cartonQty,
  //               quantity: this.pagedList[index].details[5].weekInfo[cell]
  //             } as SuggestedItem
  //           );
  //         }
  //         if (this.getWeeklySuggestionsSummary(wsIndex)[0] === 0) {
  //           this.weeklySuggestions.splice(wsIndex, 1);
  //         }
  //       }
  //     } else {
  //       if (this.pagedList[index].details[5].weekInfo[cell] > 0) {
  //         this.weeklySuggestions.push(
  //           {
  //             weekIndex: cellIndex + 1,
  //             weekStart: moment().add('day', this.selectedWeek * 7).toDate(),
  //             items: [{
  //               stockItemId: procurementItem.stockItemId,
  //               cbm: procurementItem.cbm / procurementItem.cartonQty,
  //               quantity: this.pagedList[index].details[5].weekInfo[cell]
  //             }]
  //           }
  //         );
  //       }

  //     }
  //     this.weeklySuggestions = _.sortBy(this.weeklySuggestions, ['weekIndex'], 'asc');
  //   }

  //   if (description === "Forecast") {
  //     if (value > 0) {
  //       this.pagedList[index].details[1].weekInfo[cell] = value;
  //     } else {
  //       this.pagedList[index].details[1].weekInfo[cell] = 0;
  //     }
  //     this.recalculateTableValues(index);
  //   }

  //   // for (let i = 0; i < this.pagedList.length; i++) {
  //   //   this.recalculateTableValues(i);
  //   // }
  // }

  public getWeeklySuggestionsSummary(i: number): [number, number] {
    const totalQuantity = _.sumBy(this.weeklySuggestions[i].items, 'quantity');
    const totalCBM = this.weekPlannedCBM;
    return [totalQuantity, totalCBM];
  }

  public removeWeeklySuggestion(weeklySuggestion: { weekIndex: number; weekStart: Date, items: SuggestedItem[] }): void {
    const index = this.weeklySuggestions.indexOf(weeklySuggestion);
    if (index >= 0) {
      this.weeklySuggestions.splice(index, 1);
    }
  }

  createPurchaseFromWeeklySuggestion(i: number): void {
    if (this.selectedSupplier) {
      this.purchaseCreationService.procurementSupplierId = this.selectedSupplier.pkSupplierID;
      const purchaseDate = new Date().getDate() + this.selectedWeek * 7;
      this.purchaseCreationService.purchaseDate.setDate(new Date().getDate() + this.selectedWeek * 7);
      const items = this.itemsToPO.filter(
        x => this.weeklySuggestions[i].items.some(y => y.stockItemId === x.stockItemId) && this.weeklySuggestions[i].weekIndex === x.weekIndex + 1
      );
      items.forEach(i => {
        if (i.childItems && i.childItems.length > 0) {
          i.childItems.forEach(c => {
            this.purchaseCreationService.procurementPurchaseItems.push({
              stockItemID: c.stockItemId,
              quantity: i.qty,
              date: this.purchaseCreationService.purchaseDate
            });
          });
        } else {
          this.purchaseCreationService.procurementPurchaseItems.push({
            stockItemID: i.stockItemId,
            quantity: i.qty,
            date: this.purchaseCreationService.purchaseDate
          });
        }
      })

      const tabIndex = this.weeklySuggestions[i].weekIndex;
      this.weeklySuggestions.splice(i, 1);
      this.getProcurementData(
        this.selectedSupplier.pkSupplierID,
        this.selectedCategory.categoryId
      );
      this.router.navigate(["/po-wrapper/po-creation"], { queryParams: { componentIndex: tabIndex } });
    }

  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  // 0 - last 8 weeks
  // 1 - forecast
  // 2 - inventory
  // 3 - intake
  // 4 - weeks cover
  // 5 - suggestion
  // 6 - outcome
  // 7 - outcome cover

  recalculateTableValues(index: number): void {
    for (let i = 0; i < 40; i++) {
      let last8weeks = this.pagedList[index].details[0].weekInfo[i];
      let forecast = this.pagedList[index].details[1].weekInfo[i];
      let inventory = this.pagedList[index].details[2].weekInfo[i];
      let intake = this.pagedList[index].details[3].weekInfo[i];
      let shippedIntake = this.pagedList[index].details[8].weekInfo[i];
      let suggestion = this.pagedList[index].details[5].weekInfo[i];

      if (forecast > 0 && forecast !== last8weeks) {
        // 1st week
        if (i === 0) {
          // Outcome
          this.pagedList[index].details[6].weekInfo[i] =
            inventory + intake - forecast > 0
              ? inventory + intake - forecast
              : 0;
          // Outcome cover
          if (forecast > 0) {
            this.pagedList[index].details[7].weekInfo[i] = Math.round(
              this.pagedList[index].details[6].weekInfo[i] / forecast > 0
                ? this.pagedList[index].details[6].weekInfo[i] / forecast
                : 0
            );
          } else {
            this.pagedList[index].details[7].weekInfo[i] = this.pagedList[index].details[4].weekInfo[i];
          }
        } else {
          // Outcome
          this.pagedList[index].details[6].weekInfo[i] =
            this.pagedList[index].details[6].weekInfo[i - 1] + intake - forecast > 0
              ? (this.pagedList[index].details[6].weekInfo[i - 1] + intake - forecast)
              : 0;
          // Outcome cover
          if (forecast > 0) {
            this.pagedList[index].details[7].weekInfo[i] = Math.round(
              this.pagedList[index].details[6].weekInfo[i] / forecast > 0
                ? this.pagedList[index].details[6].weekInfo[i] / forecast
                : 0
            );

            // ////// new code 08 09 2024
            // this.pagedList[index].details[7].weekInfo[i] = Math.round(
            //   inventory / forecast > 0
            //     ? inventory / forecast
            //     : 0
            // );
          } else {
            this.pagedList[index].details[7].weekInfo[i] = this.pagedList[index].details[4].weekInfo[i];
          }
        }
      } else {
        if (i === 0) {
          // Outcome
          this.pagedList[index].details[6].weekInfo[i] =
            this.pagedList[index].details[2].weekInfo[i];

          // Outcome cover
          this.pagedList[index].details[7].weekInfo[i] =
            this.pagedList[index].details[4].weekInfo[i];
        } else {
          // Outcome
          if (forecast > 0) {
            this.pagedList[index].details[6].weekInfo[i] =
              this.pagedList[index].details[6].weekInfo[i - 1] +
                intake -
                forecast >
                0
                ? this.pagedList[index].details[6].weekInfo[i - 1] +
                intake -
                forecast
                : 0;
          } else {
            this.pagedList[index].details[6].weekInfo[i] =
              this.pagedList[index].details[6].weekInfo[i - 1] +
                intake -
                last8weeks >
                0
                ? this.pagedList[index].details[6].weekInfo[i - 1] +
                intake -
                last8weeks
                : 0;
          }

          // Outcome cover
          if (forecast > 0) {
            this.pagedList[index].details[7].weekInfo[i] = Math.round(
              this.pagedList[index].details[6].weekInfo[i] / forecast > 0
                ? this.pagedList[index].details[6].weekInfo[i] / forecast
                : 0
            );
          } else {
            let tempOutcomeCover = Math.round(
              this.pagedList[index].details[6].weekInfo[i] / last8weeks > 0
                ? this.pagedList[index].details[6].weekInfo[i] / last8weeks
                : 0
            );

            this.pagedList[index].details[7].weekInfo[i] = isFinite(
              tempOutcomeCover
            )
              ? tempOutcomeCover
              : 0;
          }
        }
      }
    }
  }
}

